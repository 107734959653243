const setupLanguageCookie = () => {
  const LocalStorageLanguageKey = "gatsby-i18next-language";
  const CookieStorageLanguageKey = "Locale";

  const setCookie = (language) => {
    const expireInDays = 30;
    const date = new Date();
    date.setTime(date.getTime() + expireInDays * 24 * 60 * 60 * 1000);
    document.cookie = `${CookieStorageLanguageKey}=${language}; Domain=.uizard.io; Expires=${date.toUTCString()}; Path=/; Secure; SameSite=None`;
  };

  // First let's get it if it already exists
  if (localStorage.getItem(LocalStorageLanguageKey)) {
    setCookie(localStorage.getItem(LocalStorageLanguageKey));
  }

  Storage.prototype._setItem = Storage.prototype.setItem;
  Storage.prototype.setItem = function (key, value) {
    this._setItem(key, value);
    if (key === LocalStorageLanguageKey) {
      setCookie(value);
    }
  };
};

export const onClientEntry = () => {
  setupLanguageCookie();
};
