exports.components = {
  "component---src-cms-pages-block-page-tsx": () => import("./../../../src/cms/pages/BlockPage.tsx" /* webpackChunkName: "component---src-cms-pages-block-page-tsx" */),
  "component---src-cms-pages-pricing-page-tsx": () => import("./../../../src/cms/pages/PricingPage.tsx" /* webpackChunkName: "component---src-cms-pages-pricing-page-tsx" */),
  "component---src-cms-pages-template-category-page-tsx": () => import("./../../../src/cms/pages/TemplateCategoryPage.tsx" /* webpackChunkName: "component---src-cms-pages-template-category-page-tsx" */),
  "component---src-cms-pages-template-overview-page-tsx": () => import("./../../../src/cms/pages/TemplateOverviewPage.tsx" /* webpackChunkName: "component---src-cms-pages-template-overview-page-tsx" */),
  "component---src-cms-pages-template-page-tsx": () => import("./../../../src/cms/pages/TemplatePage.tsx" /* webpackChunkName: "component---src-cms-pages-template-page-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-index-tsx": () => import("./../../../src/pages/about/index.tsx" /* webpackChunkName: "component---src-pages-about-index-tsx" */),
  "component---src-pages-black-friday-index-tsx": () => import("./../../../src/pages/black-friday/index.tsx" /* webpackChunkName: "component---src-pages-black-friday-index-tsx" */),
  "component---src-pages-careers-index-tsx": () => import("./../../../src/pages/careers/index.tsx" /* webpackChunkName: "component---src-pages-careers-index-tsx" */),
  "component---src-pages-contact-index-tsx": () => import("./../../../src/pages/contact/index.tsx" /* webpackChunkName: "component---src-pages-contact-index-tsx" */),
  "component---src-pages-i-tsx": () => import("./../../../src/pages/i.tsx" /* webpackChunkName: "component---src-pages-i-tsx" */),
  "component---src-pages-press-index-tsx": () => import("./../../../src/pages/press/index.tsx" /* webpackChunkName: "component---src-pages-press-index-tsx" */),
  "component---src-pages-privacy-index-tsx": () => import("./../../../src/pages/privacy/index.tsx" /* webpackChunkName: "component---src-pages-privacy-index-tsx" */),
  "component---src-pages-research-index-tsx": () => import("./../../../src/pages/research/index.tsx" /* webpackChunkName: "component---src-pages-research-index-tsx" */),
  "component---src-pages-terms-of-service-index-tsx": () => import("./../../../src/pages/terms-of-service/index.tsx" /* webpackChunkName: "component---src-pages-terms-of-service-index-tsx" */),
  "component---src-pages-uizard-hat-index-tsx": () => import("./../../../src/pages/uizard-hat/index.tsx" /* webpackChunkName: "component---src-pages-uizard-hat-index-tsx" */)
}

